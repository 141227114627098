<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <h1 v-if="id != null && id.length > 0">
            Редактирование ЦЗН
          </h1>
          <h1 v-else>Создание ЦЗН</h1>

          <v-row>
            <v-autocomplete
              label="Регион"
              v-model="department.region"
              :items="regionsList"
              :item-text="({ name, netType }) => name + ` (${regionNetType(netType)})`"
              return-object
              style="width: 40%"
              @change="onSelectRegionChange($event)"
              :readonly="$user.role === 'ROLE_REGIONAL_ADMINISTRATOR'"
            ></v-autocomplete>
          </v-row>
          <v-row>
            <v-select
              :disabled="!this.department.region"
              label="Тип"
              v-model="department.type"
              :items="typeOptions"
              item-text="text"
              item-value="type"
              style="width: 40%"
              @change="onSelectTypeChange($event)"
            ></v-select>
          </v-row>
          <v-row>
            <v-text-field
              label="Краткое название"
              v-model="department.name"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
                label="Полное название"
                v-model="department.fullName"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
                label="Адрес"
                v-model="department.address"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-select
                label="Уровень"
                v-model="department.level"
                :items="levelOptions"
                item-text="text"
                item-value="type"
                style="width: 40%"
            ></v-select>
          </v-row>
          <v-row>
            <v-text-field
                label="Координаты"
                v-model="department.coords"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-checkbox
              label="Активная запись"
              v-model="department.isActive"
            ></v-checkbox>
          </v-row>


<!--          <v-row v-if="user.primaryRole === 'ROLE_CZN_MANAGER' || user.primaryRole === 'ROLE_CZN_TERRITORIAL'">-->
<!--            <v-radio-group-->
<!--                v-model="searchType"-->
<!--                label="Режим поиска ЦЗН">-->
<!--              <v-radio value="department" label="По внутреннему реестру" @change="user.department = null; isManager = null"></v-radio>-->
<!--              <v-radio value="egrul" label="По ЕГРЮЛ" @change="user.department = null; isManager = null"></v-radio>-->
<!--            </v-radio-group>-->
<!--          </v-row>-->

<!--          <v-row>-->
<!--            <EgrulInput-->
<!--                v-if="searchType === 'egrul'"-->
<!--                @changeNameOfUser="changeNameOfUser"-->
<!--                :init-value="user.department"-->
<!--            ></EgrulInput>-->
<!--          </v-row>-->


          
          <div class="save-buttons mt-24">
            <v-btn
              color="primary"
              outlined
              @click="$router.push('/config/departments')"
              >Отмена</v-btn
            >
            <v-btn
              :disabled="submitButtonDisabled"
              color="primary"
              @click="submit"
              >Сохранить</v-btn
            >
          </div>
        </div>

        <div
          v-if="errorText != null"
          class="error"
          style="position: fixed; bottom: 0"
        >
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height: 1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>

<script>
// import EgrulInput from "../elements/EgrulInput";
export default {
  props: {},
  // components: { EgrulInput },
  data() {
    return {
      apiLoaded: false,
      regionsList: [],
      id: null, //id from url param
      submitButtonDisabled: !(this.$user.role === "ROLE_ADMIN" || this.$user.role === "ROLE_REGIONAL_ADMINISTRATOR"),
      department: {
        name: null,
        fullName: null,
        region: null,
        address: null,
        isActive: true,
        coords:  null,
        type: 'territorial_czn',
      },
      errorText: null,
      searchType: "department",
      typeOptionsGeneral: [
        {text: "Управляющий ЦЗН", type: "manager_czn"},
        {text: "Территориальный ЦЗН", type: "territorial_czn"},
        {text: "Орган исполнительной власти субъекта РФ", type: "oiv"}
      ],
      levelOptions: [
        {text: "1", type: 1},
        {text: "2", type: 2},
        {text: "3", type: 3}
      ]
    };
  },
  computed: {
      canAddUczn() {
        return this.department?.region?.netType === 'CENTRALIZED'
      },
    typeOptions() {
      if(this.canAddUczn) {
        return this.typeOptionsGeneral
      }
      return this.typeOptionsGeneral.slice(1);
    },
    regionNetType() {
      return (type) => {
        const reg = {
        'CENTRALIZED':  'централизованный',
        'DECENTRALIZED': 'децентрализованный'
      }
        return reg[type]
      }
    }
  },
  methods: {
    async loadDepartmentInfo() {
      let req = await this.$getApi("/department/getById", {
        id: this.id,
      });
      if (req.ok) {
        this.department = req.payload;
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    async loadRegionsList() {
      let req = await this.$getApi("/getRegionsList");
      if (req.ok) {
        this.regionsList = req.payload;
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    onSelectTypeChange(opt) {
      this.department.type = opt
      this.$forceUpdate();
    },

    onSelectRegionChange(opt) {
      this.department.region = opt
      this.$forceUpdate();
    },

    validate() {
      if (this.department.name == null || this.department.name.length === 0)
        return "Укажите название ЦЗН";
      if (this.department.fullName == null || this.department.fullName.length === 0)
        return "Укажите полное нащвание ЦЗН";
      if ((this.department.region == null || this.department.region.length === 0)) {
        return "Выберите регион"
      }
      return true;
    },

    async submit() {
      let t = this.validate();
      if (t !== true) {
        this.errorText = t;
        return;
      }
      this.errorText = null;

      this.submitButtonDisabled = true;


      let req = await this.$postApi("/department/saveDepartment", this.department);
      if (req.ok) {
        this.$router.push("/config/departments");
        this.$initializeDepartments() // обновим кэш
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
      this.submitButtonDisabled = false;
    },
  },
  async beforeMount() {
    this.id = this.$route.params.id;
    let p1 = this.loadRegionsList();
    if(this.id)
      await this.loadDepartmentInfo(this.id);
    await p1;

    if(this.$user.role === 'ROLE_REGIONAL_ADMINISTRATOR'){
      this.department.region = this.regionsList.find(r => r.code === this.$user.region.code);
    }

    this.apiLoaded = true;
  },
};
</script>
